import React, {useEffect, useState} from 'react';
import './style.scss';
import config from './config.json';
import media from '../../media';
import Layout from "../../components/layout/main";
import Polaroids from "../../components/polaroids/main";
import AnimationOnScroll from '../../components/animation-on-scroll/main';
import AnimationOnScroll2 from '../../components/animation-on-scroll2/main';
import AnimationOnScroll3 from '../../components/animation-on-scroll3/main';
import CardAnimation from '../../components/card-animation/main';

export default function Services(props) {

    return (
        <div className="services">
            <Layout pageId={1}>
                <div className="services-block">
                    <h1 className="heading" dangerouslySetInnerHTML={{__html:config.services.heading}}></h1>
                </div>

                <div className="leadership-block">
                    <h2 className="heading" dangerouslySetInnerHTML={{__html:config.leadership.heading}}></h2>
                    <div className="paragraphs">
                        {config.leadership.body.map((paragraph, key) => {
                            return(
                                <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                            );
                        })}
                    </div>
                    <a className="learn-btn" dangerouslySetInnerHTML={{__html:config.leadership.button1}} href={config.leadership.url}></a>
                </div>

                <CardAnimation>
                    <div className="coaches-block">
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.coaches.heading}}></h2>
                        <Polaroids alt="Leadership coaches" content={config.coaches.polaroid} />
                    </div>
                </CardAnimation>

                {/* <div className="talent-block">
                    <AnimationOnScroll2>
                        <div className="headings">
                            {config.talent.heading.map((heading, key) => {
                                return(
                                    <h2 className="heading" key={key} dangerouslySetInnerHTML={{__html:heading}}></h2>
                                );
                            })}
                        </div>
                    </AnimationOnScroll2>

                    <AnimationOnScroll3>
                        <div className="paragraphs">
                            {config.talent.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </AnimationOnScroll3>

                </div> */}

                <div className="virtual-block">
                    <AnimationOnScroll2>
                        <div className="headings">
                            {config.virtual.heading.map((heading, key) => {
                                return(
                                    <h2 className="heading" key={key} dangerouslySetInnerHTML={{__html:heading}}></h2>
                                );
                            })}
                        </div>
                    </AnimationOnScroll2>

                    <AnimationOnScroll3>
                        <div className="paragraphs">
                            {config.virtual.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                    </AnimationOnScroll3>
                </div>

                <div className="transform-block">
                    <AnimationOnScroll2>
                        <h2 className="heading" dangerouslySetInnerHTML={{__html:config.transform.heading}}></h2>
                    </AnimationOnScroll2>

                    <AnimationOnScroll3>
                        <div className="paragraphs">
                            {config.transform.body.map((paragraph, key) => {
                                return(
                                    <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                );
                            })}
                        </div>
                        <a className="transform-btn" dangerouslySetInnerHTML={{__html:config.transform.button}} href={config.transform.url}></a>
                    </AnimationOnScroll3>
                </div>

                <div className="powered-block">
                    <AnimationOnScroll2>
                        <div className="paragraphs">
                                {config.powered.body.map((paragraph, key) => {
                                    return(
                                        <p key={key} dangerouslySetInnerHTML={{__html:paragraph}}></p>
                                    );
                                })}
                        </div>
                    </AnimationOnScroll2>
                </div>
            </Layout>
        </div>
    )
}
